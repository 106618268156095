import settings from "../config/settings";

export const SendUserBack = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const backTo = urlParams.get("back_to");

  if (backTo?.length) {
    window.location = backTo;
    return;
  }
  if (settings.MANAGER_URL) {
    window.location = settings.MANAGER_URL;
    return;
  }

  let url = document.referrer;
  if (
    url.includes("telkey.com") ||
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    window.location = url;
  }
};
